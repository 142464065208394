import TabContainerElement from '@github/tab-container-element';
import client from '@/lib/api/client';
import { markdownPreviewPath } from '@/lib/rails-routes';

const init = (): void => {
  const elements = document.querySelectorAll<TabContainerElement>('.js-markdown-preview');
  if (elements.length === 0) return;

  for (const element of elements) {
    // SEE: https://github.com/github/tab-container-element
    element.addEventListener('tab-container-changed', async (event: CustomEvent) => {
      const target = event.panel as HTMLDivElement;
      const contentElement = target.querySelector('.js-markdown-preview-content');
      if (!contentElement) return;

      const textarea = element.querySelector('textarea')!;
      const { data } = await client.patch<string>(markdownPreviewPath(), {
        body: textarea.value,
      });
      contentElement.innerHTML = data;
    });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  init();
});
