<template>
  <div>
    <a
      data-offset="100,0"
      data-toggle="dropdown"
      aria-expanded="false"
      href=""
      @click.prevent="handleClickBell"
    >
      <span class="fa-layers fa-fw has-counter-icon">
        <i class="fas fa-bell"></i>
        <span v-show="state.unread" class="fa-layers-counter">
          &nbsp;
        </span>
      </span>
    </a>
    <div class="dropdown-menu dropdown-menu-right">
      <h6 class="dropdown-header">直近の通知</h6>
      <div class="dropdown-divider" />

      <div v-for="notification in notifications" :key="notification.id">
        <a class="dropdown-item" :href="notification.path">
          <div class="my-2">
            <div class="mb-2" v-text="notification.body" />
            <div
              class="text-xs text-right text-secondary"
              v-text="notification.formattedTime"
            />
          </div>
        </a>
        <div class="dropdown-divider" />
      </div>

      <div class="px-4 text-xs text-right">
        <a :href="notificationsPath">通知一覧へ</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  defineProps, reactive,
} from 'vue';
import { read as readNotification } from '@/lib/api/notification_reads';

type Notification = {
  id: number,
  body: string,
  path: string,
  formattedTime: string
}

type Props = {
  unread: boolean,
  notifications: Notification[],
  notificationsPath: string
}

type State = {
  unread: boolean
}

const props = defineProps<Props>();

const state = reactive<State>({
  unread: props.unread,
});
const handleClickBell = async () => {
  state.unread = false;
  await readNotification();
};
</script>
